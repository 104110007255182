body {
  height: auto;
}
img {
  max-width: 100%;
}
span.label {
  display: inline-block;
}
.react-datepicker-popper {
  z-index: 99;
}
.text-box {
  background-color: #f2f3f4;
  padding: 10px 10px 0;
}
.text-box > div {
  background-color: #fff;
  padding: 10px;
  border-radius: 4px;
  font-size: 15px;
}

.select > div {
  z-index: 99;
}

.line-height-30 {
  line-height: 30px;
}
.close-toggle {
  position: absolute;
  right: 0;
  top: 10px;
  border: 0;
  font-size: 24px;
}

.upload-default {
  height: 200px;
  line-height: 200px;
  vertical-align: middle;
  text-align: center;
  font-size: 48px;
}

.header .navbar-header {
  width: 100%;
}
.sidebar .nav > li {
  margin: 8px 0;
}
.sidebar .nav > li > a {
  position: relative;
  z-index: 10;
  background: rgba(255, 255, 255, 0.4);
  padding: 8px 12px;
  font-size: 13px;
}
.sidebar .nav > li.active > a {
  position: relative;
  z-index: 10;
  color: #fff;
}

.list-group.list-group-flush li {
  padding: 0;
}
.list-group.list-group-flush li > a {
  position: relative;
  display: block;
  padding: 10px 15px;
}
.list-group.list-group-flush li > a:hover {
  background: #eee;
}
.list-group.list-group-flush li > a strong {
  color: #333;
}
.list-group.list-group-flush .list-group-item .list-group-date {
  position: absolute;
  right: 10px;
  top: 12px;
  color: #777;
}

.b-t-l-r-0 {
  border-top-left-radius: 0;
}
.b-b-l-r-0 {
  border-bottom-left-radius: 0;
}
.b-t-r-r-0 {
  border-top-right-radius: 0;
}
.b-b-r-r-0 {
  border-bottom-right-radius: 0;
}

/* coverImg */
.coverImg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  overflow: hidden;
}
.coverImg::before {
  content: "";
  display: block;
  padding-top: 64%;
}
.coverImg img.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
/* coverImg */
.info_ul {
}
.info_ul ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.info_ul ul li {
  margin-bottom: 8px;
  font-size: 15px;
}
/* responsive_img_area */
.responsive_img_area {
  width: 180px;
  margin: auto;
}
.img_item {
  background-color: #fafafa;
  border: solid 1px #eee;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 5px;
}
.img_item::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 62.25%;
}
.img_item img {
  position: absolute;
  max-width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  z-index: 9;
}

/*Swiper*/
.panel.panel-swiper {
  margin-bottom: 45px;
}
.swiper-wrapper {
}
.swiper-wrapper .swiper-slide {
  background: transparent !important;
}
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0;
}
.widget-stats .stats-icon,
.widget.widget-stats .stats-icon {
  font-size: 28px;
}

.main-banner {
  position: relative;
  overflow: hidden;
  background-color: #fff;
  border-radius: 6px;
}
.main-banner .img {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
}
.main-banner .img::before {
  content: "";
  display: block;
  padding-top: 24.25%;
}
.main-banner .category {
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -15px;
}
.main-banner .category span {
  font-size: 13px;
  padding: 5px 5px;
  line-height: 30px;
}
.main-banner .cont {
  position: relative;
  padding: 10px 15px;
}
.main-banner .cont strong {
  color: #333;
  font-size: 18px;
}
.main-banner .cont p {
  color: #777;
  margin: 0;
}
.col-2-5 {
  width: 20%;
  float: left;
  padding: 0 10px;
}
.width-img {
  display: inline-block;
}
.width-img > div {
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #eee;
  width: 120px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.width-img img {
  max-width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* panel css */
.underline {
  border-bottom: 1px solid #e2e7eb !important;
}
.title-info-ul {
  margin: 0;
  display: flex;
  padding: 0;
}
.title-info-ul li {
  position: relative;
  font-size: 14px;
  list-style: none;
  float: left;
  padding: 0 10px;
}
.title-info-ul li:first-child:before {
  display: none;
}
.title-info-ul li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -7px;
  width: 2px;
  height: 14px;
  background-color: #ddd;
}
/*order*/
.order-item-stap {
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  padding: 0 5px;
  margin: 0 0 20px;
  border-radius: 5px;
}
.order-item-stap > li {
  position: relative;
  list-style: none;
  float: left;
  flex: 0 0 25%;
  max-width: 25%;
  padding: 10px;
}
.order-item-stap > li:first-child:before {
  display: none;
}
.order-item-stap > li > a {
  position: relative;
  display: flex;
  padding: 15px;
  border: solid 1px #eee;
  border-radius: 5px;
}
.order-item-stap > li a::before {
  content: "\f105";
  position: absolute;
  right: 15px;
  top: 50%;
  font-family: "Font Awesome 5 Free";
  height: 20px;
  line-height: 20px;
  margin-top: -10px;
  font-size: 24px;
  color: #c9cfe0;
  font-weight: 900;
}
.order-item-stap > li.active a::before {
  color: #0078ff;
}
.order-item-stap > li.active > a {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}
.order-item-stap > li > a:hover {
  text-decoration: none;
}
.order-item-stap > li.active > a .cont span {
  color: #0078ff;
  font-weight: 700;
}
.order-item-stap > li.active > a .circle {
  background-color: #0078ff;
}
.order-item-stap > li .circle {
  display: flex;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #c9cfe0;
  justify-content: center;
  align-items: center;
}
.order-item-stap > li > a .circle i {
  font-size: 24px;
  color: #fff;
}
.order-item-stap > li > a .cont {
  margin-left: 15px;
  justify-content: center;
  align-items: center;
}
.order-item-stap > li > a .cont span {
  display: block;
  color: #444;
  font-size: 16px;
  font-weight: 400;
}
.order-item-stap > li > a .cont strong {
  color: #111;
  font-size: 28px;
}

.order-item-stap.cs > li.active a::before {
  color: #ff1b30;
}
.order-item-stap.cs > li.active > a {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}
.order-item-stap.cs > li > a:hover {
  text-decoration: none;
}
.order-item-stap.cs > li.active > a .cont span {
  color: #ff1b30;
  font-weight: 700;
}
.order-item-stap.cs > li.active > a .circle {
  background-color: #ff1b30;
}

/* order - detail */
.order-item-ul {
  margin: 0;
  padding: 0;
}
.order-item-ul li {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 10px 15px;
  border-top: solid 1px #eee;
  align-items: center;
}
.order-item-ul li.total {
  border: solid 1px #ff1b30;
  font-weight: 700;
}
.order-item-ul li:first-child {
  border: 0;
}
.order-item-ul li span {
  flex: 0 0 auto;
  font-size: 14px;
}
.order-item-ul li .left {
  width: 100px;
  color: #444;
}
.order-item-ul li .right {
  text-align: right;
  color: #111;
}
.order-item-ul.money li span {
  flex: 0 0 50%;
}
.order-item-ul.money li .left {
  text-align: left;
}
.order-item-ul.money li .right {
  text-align: right;
}
.product-list-wrap {
}
.product-list-wrap ul {
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  padding: 0;
  margin: 0;
}
.product-list-wrap ul li {
  list-style: none;
}
.product-list-wrap ul li.product-list {
  display: flex;
  flex: 0 0 25%;
  max-width: 25%;
  float: none;
  padding: 0 10px;
  margin-bottom: 15px;
  border-radius: 0;
}
.product-list-wrap ul li a {
  width: 100%;
  display: block;
  border: solid 1px #eee;
  border-radius: 5px;
  overflow: hidden;
}
.product-list-wrap ul li.product-list .category {
  position: absolute;
  top: 8px;
  left: 0;
  z-index: 10;
}
.product-list-wrap ul li.product-list .category span {
  font: 14px;
  margin-left: 5px;
}
.product-list-wrap ul li.product-list .img {
  background-color: #fafafa;
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.product-list-wrap ul li.product-list .img::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 100%;
}
.product-list-wrap ul li.product-list .img img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.product-list-wrap ul li.product-list .ticket {
  background-color: #fafafa;
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.product-list-wrap ul li.product-list .ticket img {
  max-width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.product-list-wrap ul li.product-list .ticket::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 144%;
}
.product-list-cont {
  padding: 10px;
}
.product-list-cont .tit {
  max-height: 48px;
  color: #333;
  font-size: 16px;
  line-height: 1.4;
  overflow: hidden;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.product-list-cont .desc {
  color: #555;
}
.product-list-cont .price-info {
  margin-top: 10px;
  height: auto;
}
.product-list-cont .price-info .price {
  display: block;
  font-size: 15px;
  color: #444;
  font-weight: 600;
}
.product-list-cont .price-info .discount {
  text-decoration: line-through;
  font-size: 13px;
  color: #999;
}
.product-list-cont .price-info .date {
  margin: 10px 0 0;
  display: block;
  color: #999;
}
.panel .panel-body > p,
.vertical-box-inner-cell > p {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
}
.product-img {
  background-color: #fafafa;
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.product-img::before {
  content: "";
  display: block;
  width: 100%;
  padding-top: 100%;
}
.product-img img {
  content: "";
  position: absolute;
  height: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 5px;
}
.product-img .day {
  position: absolute;
  left: 5px;
  top: 5px;
  font-size: 14px;
}

.order-product-list {
  display: flex;
  flex-direction: row;
  padding: 10px;
  border-radius: 5px;
  border: solid 1px #eee;
}
.order-product-list .img {
  background-color: #f5f5f5;
  position: relative;
  display: flex;
  width: 100px;
  height: 100px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  overflow: hidden;
}
.order-product-list .day {
  position: absolute;
  left: 5px;
  top: 5px;
  font-size: 14px;
}
.order-product-list .img img {
  max-width: 100%;
  height: 100%;
}
.order-product-list .cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}
.order-product-list .cont strong {
  display: block;
}
.order-product-list .cont strong a {
  font-size: 15px;
  color: #111;
}
.order-product-list .cont .option {
  margin-top: 5px;
  font-size: 13px;
  color: #777;
}
.order-product-list .cont .price {
  font-size: 14px;
  color: #333;
}
.common-status {
  background-color: #fff;
  border-radius: 5px;
  list-style: none;
  padding: 0;
}
.common-status li {
  display: flex;
  width: 100%;
  flex: 1 0 auto;
  flex-direction: row;
  padding: 5px 0;
  border-bottom: solid 1px #eee;
  font-size: 14px;
}
.common-status li.column {
  flex-direction: column;
}
.common-status .flex-end {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.common-status li:last-child {
  content: "";
  border: 0;
}
.common-status li > .left {
  display: flex;
  flex: 1;
  justify-content: flex-start;
}
.common-status li > .right {
  display: flex;
  align-items: center;
  flex: 1;
  text-align: right;
  justify-content: flex-end;
}
.common-status li > .right > span {
  font-size: 15px;
  font-weight: bold;
}
.common-status li > p {
  margin: 0;
}
.common-status-btn {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
}
.common-status-btn li {
  display: flex;
  flex: 1 0 auto;
  padding: 5px 0;
}
.common-status-btn li a,
.common-status-btn li button {
  width: 100%;
  display: block;
}
.common-status-btn li a i,
.common-status-btn li button i {
  margin-right: 5px;
}
.order-product address {
  background-color: #fff;
  min-height: 250px;
  padding: 10px;
  border-radius: 5px;
}
.qrcode {
  max-width: 100%;
  height: auto;
}
.input-option {
  display: flex;
  flex-direction: row;
  margin: 10px -5px 0;
}
.input-option > div {
  flex: 1;
  padding: 0 5px;
}
.sets-list {
  margin: 0;
  padding: 0;
}
.sets-list li {
  list-style: none;
  padding: 15px 0;
  border-bottom: solid 1px #eee;
}
.all-check {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.all-check .check {
  display: flex;
  flex: 1;
  height: 30px;
  align-items: center;
}
.all-check .check label {
  margin: 0 0 0 5px;
  font-size: 14px;
  font-weight: bold;
}
.all-check .check input {
  width: 16px;
  height: 16px;
}
.all-check .btn-box {
}
.product-list {
  margin: 0;
  padding: 0;
}
.product-list li {
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  padding: 15px 0;
  border-bottom: solid 1px #eee;
}
.product-list li .check {
  width: 24px;
}
.product-list li .check label {
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-bottom: 0;
}
.product-list li .check input {
  width: 16px;
  height: 16px;
}
.product-list li .info {
  flex: 1;
}
.product-list li .count {
  flex: 0 0 10%;
  font-size: 14px;
}
.product-list li .price {
  flex: 0 0 20%;
  text-align: center;
  font-size: 14px;
}
.product-list li.total {
  border-top: solid 2px #333;
  border-bottom: solid 2px #333;
}
@media (max-width: 1179.98px) {
  .page-with-wide-sidebar.page-sidebar-toggled .sidebar,
  .page-with-wide-sidebar.page-sidebar-toggled .sidebar-bg {
    width: 250px;
    left: 0;
  }
  .page-with-wide-sidebar .sidebar,
  .page-with-wide-sidebar .sidebar-bg {
    width: 250px;
    left: -250px;
  }
  .page-with-wide-sidebar.page-sidebar-toggled .content {
    margin-left: 250px;
  }
  .page-with-wide-sidebar .footer {
    margin-left: 20px;
  }
  .page-with-wide-sidebar.page-sidebar-toggled .footer {
    margin-left: 280px;
  }
  .page-with-wide-sidebar .content {
    margin-left: 0;
  }
  .header .navbar-toggle {
    display: block;
  }
}

@media (max-width: 991px) {
  .page-with-wide-sidebar.page-sidebar-toggled .content {
    margin-left: 250px;
  }
  .col-2-5 {
    width: 50%;
    float: left;
    padding: 0 10px;
  }
  .col-2-5.last {
    width: 100%;
    float: left;
    padding: 0 10px;
  }
  .order-item-stap {
    padding: 0;
  }
  .order-item-stap > li {
    position: relative;
    list-style: none;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0;
    border-bottom: solid 1px #ddd;
  }
  .order-item-stap > li a {
    border: 0;
    border-radius: 0;
  }
  .order-item-stap > li.active a {
    box-shadow: none;
  }
}

@media (max-width: 767px) {
  .page-with-wide-sidebar.page-sidebar-toggled .content {
    margin-left: 0px;
  }
}

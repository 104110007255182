html,
body {
  margin: 0;
  padding: 0;
  background: #eee;
}
* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.page {
  background: white;
  display: block;
  margin: 0 auto;
  height: 100vh;
  width: 100%;
}
.page .print {
  width: 100%;
  height: 100%;
}
.page .print-page {
  padding: 5mm;
  height: 50%;
}
.page .print-page > div {
  height: 100%;
  border: solid 2px black;
}
.page .print-page.not {
  padding: 1cm;
  height: 100%;
}
.page .print-page.not > div {
  padding: 0;
  border: solid 2px black;
}
/* */
.page .print-page-header {
  padding: 2.5mm;
  display: flex;
  border-bottom: solid 1px black;
}
.page .print-page-body {
  padding: 2.5mm;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: solid 1px black;
}
.page .print-page-body p {
  margin: 0;
  font-size: 14pt;
}
.page .print-page-body .qr-img {
  width: 140px;
  height: 140px;
  border: 3px black solid;
  margin-right: 5mm;
}
.page .print-page-footer {
  display: flex;
  flex-direction: row;
  padding: 2.5mm;
}
.order-product-ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}
.order-product-ul > li {
  width: 50%;
  flex: 0 0 50%;
  padding: 0 10px;
}
.order-product-ul > li > div {
  display: flex;
  flex: 1;
  border-bottom: 1px gray solid !important;
  justify-content: space-between;
  padding: 10px 0;
  font-size: 12pt !important;
}
.order-product-ul > li > .first {
  flex: 1;
}
.order-product-ul > li > .second {
  width: 50px;
  text-align: center;
}
.page .print-page-footer table + table {
  margin-left: 5mm;
}
.page .print-page table {
  font-size: 12pt;
}
.qr-img {
  width: 110px;
  height: 110px;
  border: 3px black solid;
  padding: 3mm;
}
.qr-img img {
  max-width: 100%;
}
.page2 {
  background: white;
  display: block;
  margin: 0 auto;
  padding: 0;
  height: 100vh;
  width: 100vh;
}
.page2 .print {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}
.page2 .print .print-page {
  width: 20%;
  height: 100%;
  flex: 0 0 20%;
  padding-right: 2mm;
}
.page2 .print .print-page:last-child {
  padding: 0;
}
.page2 .print .print-page > div {
  height: 100%;
  border: solid 2px black;
}
/* */
.page2 .print .print-page-header {
  padding: 2mm 0;
  display: flex;
  border-bottom: solid 1px black;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.page2 .print .print-page-header strong {
  display: block;
  text-align: center;
}
.page2 .print .print-page-body {
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 0;
}
@media all {
  .pagebreak {
    display: none;
  }
}

@media print {
  html,
  body {
    margin: 0;
    padding: 0;
    background: #fff;
  }
  .pagebreak {
    page-break-before: always;
  }
  .page,
  .page2 {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    page-break-after: always;
  }
}
.box {
  font-size: 10pt;
}
.table-invoice {
  width: 100%;
}
.table-invoice.week th {
  padding: 5px 0;
  border-bottom: 2px black solid;
  font-size: 11pt;
  font-weight: bold;
  text-align: center;
}
.table-invoice.week td {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  font-size: 8pt !important;
  color: #000;
  vertical-align: middle;
  letter-spacing: -0.6px;
}
.table-invoice th {
  padding: 5px 0;
  border-bottom: 2px black solid;
  font-size: 11pt;
  font-weight: bold;
  text-align: center;
}
.table-invoice td {
  color: #000;
  font-size: 11pt !important;
  vertical-align: middle;
  letter-spacing: -0.6px;
  border-top: 0 !important;
  border-bottom: 1px gray solid !important;
}
.table-invoice td strong {
  font-size: 10pt;
}
.table-invoice td p {
  margin: 0;
}
.font16 {
  font-size: 16pt;
}
.font14 {
  font-size: 14pt;
}
